<template>
  <v-main :style="{ paddingLeft: `${drawerWidth}px` }">
    <portal-target name="core-view" />
    <div id="core-view" style="min-height: calc(100vh - 192px)">
      <div class="contents-inner" :class="[$route.name]">
        <nuxt />
      </div>
    </div>

    <div
      class="d-flex d-md-none menu_fixed-button"
      style="position: fixed; top: 1em; right: 1em; z-index: 2"
    >
      <v-slide-x-transition group>
        <template v-for="(button, index) in buttons">
          <v-btn
            v-if="!button.hidden && !button.pullDownList"
            :key="index"
            :color="button.color"
            :disabled="button.disabled === true"
            :outlined="button.outlined"
            class="px-n1 mx-1"
            :class="button.class"
            rounded
            @click.stop="button.click ? button.click() : null"
          >
            <v-icon>{{ button.icon }}</v-icon>
            <span class="d-none d-sm-flex">{{ button.text }}</span>
          </v-btn>
          <v-menu
            v-else-if="!button.hidden && button.pullDownList"
            :key="index"
          >
            <template #activator="{ on }">
              <v-btn
                :key="index"
                :color="button.color"
                :disabled="button.disabled === true"
                :outlined="button.outlined"
                class="px-n1 mx-1"
                :class="button.class"
                rounded
                v-on="on"
              >
                <v-icon>{{ button.icon }}</v-icon>
                <span class="d-none d-md-flex">{{ button.text }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(table, pullDown_i) in button.pullDownList"
                :key="pullDown_i"
                :input-value="table.active"
                @click.stop="table.click ? table.click() : null"
              >
                <v-list-item-title>
                  {{ $t(table.title) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-slide-x-transition>
    </div>
  </v-main>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CoreView',
  data: () => {
    return {
      buttons: [],
    }
  },
  watch: {
    $route(val) {
      this.title = val.name
      this.buttons = []
    },
  },
  created() {
    this.setListener()
  },
  computed: {
    ...mapState('app', ['drawerWidth']),
  },
  methods: {
    setListener() {
      // emitで発火させたイベント名にする
      this.$nuxt.$on('set-app-btn', this.setAppBtn)
    },
    setAppBtn(_btn) {
      this.buttons = _btn
    },
  },
}
</script>
