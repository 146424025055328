import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListGroup,{class:{ subgroup: _vm.link.subGroup },attrs:{"prepend-icon":_vm.link.icon,"no-action":"","value":false,"color":"white","sub-group":_vm.link.subGroup},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v("\n      "+_vm._s(_vm.link.title)+"\n    ")])]},proxy:true}])},[_vm._v(" "),_vm._l((_vm.link.items),function(subLink,subIndex){return [(subLink.show === undefined ? true : subLink.show)?[(subLink.group)?_c('Navbar',{key:subIndex,attrs:{"link":subLink}}):(_vm.checkShowMenu(subLink.to))?_c(VListItem,{key:subIndex,attrs:{"to":subLink.click ? null : ("" + (subLink.to)),"input-value":_vm.checkActiveDrawer(subLink.to)},on:{"click":function($event){$event.stopPropagation();subLink.click ? subLink.click() : null}}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItemTitle,_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v("\n              "+_vm._s(subLink.text)+"\n            ")]),_vm._v(" "),(subLink.icon)?_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(subLink.icon))])],1):_vm._e()]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(subLink.text))])])],1):_vm._e()]:_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }