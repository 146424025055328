<template>
  <v-card :style="styles" :height="fullHeight ? '100%' : ''" v-bind="$attrs">
    <v-card
      v-if="topBar"
      :color="color"
      class="v-card--material__header d-flex align-center justify-space-between"
      dark
      min-height="40"
    >
      <v-card-title>
        {{ title }}
        <v-tooltip
          v-if="guide"
          top
          :open-on-hover="false"
          :open-on-click="true"
          max-width="25em"
          z-index="100"
        >
          <template #activator="{ on }">
            <v-icon @click="on.click" v-on="on">
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span>{{
            guide.startsWith('Q0') ? $constants.GUIDE[guide] : guide
          }}</span>
        </v-tooltip>
        <div v-if="help">
          <v-icon @click="$emit('open-category-guide', help)">
            mdi-help-circle-outline
          </v-icon>
        </div>
      </v-card-title>
      <v-spacer />
    </v-card>
    <div>
      <v-card-title v-if="this.$slots.title" class="text-subtitle-1">
        <slot name="title" />
      </v-card-title>
      <div class="v-card-body">
        <slot name="main" />
        <slot name="buttons" />
      </div>
    </div>

    <v-divider v-if="$slots.actions" />
  </v-card>
</template>

<script>
export default {
  name: 'MaterialCard',

  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: 'success',
    },
    elevation: {
      type: [Number, String],
      default: 2,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: [Number, String],
      default: 20,
    },
    title: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    topBar: {
      type: Boolean,
      default: false,
    },
    heightOffsetPow: {
      type: [Number, String],
      default: 3,
    },
    guide: {
      type: String,
      default: undefined,
    },
    help: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    hasOffset() {
      return this.$slots.header || this.$slots.offset || this.title || this.text
    },
    styles() {
      if (!this.hasOffset) return null

      return {
        marginBottom: `${this.offset}px`,
        marginTop: `${this.offset * 2}px`,
      }
    },
  },
}
</script>
