<template>
  <v-footer id="core-footer" height="40">
    <portal-target name="btn-noti" />
    <v-spacer />
    <span class="font-weight-light mr-5">
      {{ buildString }}
    </span>
    <span class="font-weight-light copyright">
      &copy;
      {{ new Date().getFullYear() }}
      {{ copyright }} <span style="font-style: italic">{{ version }}</span>
    </span>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    copyright: ' OEC Co., Ltd.',
    version: 'Ver1.2.2',
    links: [],
  }),
  computed: {
    buildString: {
      get: function () {
        return process.env.NUXT_ENV_BUILD_DATE
      },
    },
  },
}
</script>

<style scoped>
#core-footer {
  z-index: 0;
}
</style>
