<template>
  <v-card :elevation="elevation">
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th
              v-for="(row, index) in tableItems.header"
              :key="index"
              :class="row.class"
            >
              {{ row.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in tableItems.data" :key="index">
            <td v-if="row.name" class="primary--text">
              {{ row.name }}
              <v-progress-linear
                v-if="row.value"
                background-color="YellowUltraLight"
                color="YellowDark"
                :value="(Number(row.value) / Number(paramater)) * 100"
              />
            </td>
            <td v-if="row.value" class="text-right">
              {{ $utils.addComma(row.value) }} {{ row.unit }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "MaterialTable",
  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: "success",
    },
    elevation: {
      type: [Number, String],
      default: 0,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: [Number, String],
      default: 20,
    },
    title: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    paramater: {
      type: Number,
      default: 100,
    },
    tableItems: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  computed: {
    hasOffset() {
      return this.$slots.header || this.$slots.offset || this.title || this.text
    },
    styles() {
      if (!this.hasOffset) return null

      return {
        height: `calc(100% - ${this.offset * this.heightOffsetPow}px)`,
        marginBottom: `${this.offset}px`,
        marginTop: `${this.offset * 2}px`,
      }
    },
  },
}
</script>
