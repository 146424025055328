<script>
import { Bar } from 'vue-chartjs'
import 'chartjs-plugin-colorschemes'

export default {
  name: 'BarChart',
  extends: Bar,
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: String,
      default: '',
    },
  },
  watch: {
    data(val) {
      this.renderChart(val, this.options)
    },
  },
  mounted() {
    this.renderChart(this.data, this.options)
  },
}
</script>
