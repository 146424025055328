<template>
  <v-card style="z-index: 11">
    <v-navigation-drawer
      id="app-drawer"
      class="aside-menu"
      v-model="inputValue"
      app
      floating
      mobile-breakpoint="1180"
      persistent
      ref="drawer"
    >
      <div class="aside-menu-inner">
        <!-- Logo -->
        <div class="logo" style="max-width: 300px">
          <img src="/img/logo/horizontal.svg" alt="carbonote" />
        </div>

        <!-- UserInfo -->
        <div class="user-info">
          <v-list-item>
            {{ user.username }}{{ $t('common.name') }}
          </v-list-item>
        </div>

        <!-- Language pulldown -->
        <div class="language-info">
          <v-select
            v-model="i18nLocale"
            :items="this.$constants.LANGUAGE.COUNTRIES"
            item-text="label"
            item-value="value"
            :dense="true"
            @change="changeLanguage"
            :label="this.$t('common.language')"
            outlined
            style="width: 60%; min-width: 180px"
          />
        </div>

        <!-- AlertInfo -->
        <!-- <div class="alert-info" to="btn-noti">
          <v-btn
            v-if="!initModeFlag && news.newsSize != 0"
            dark
            left
            color="#FFB74D"
            @click="TOGGLE_NOTIFICATION"
            class="btn-fab"
          >
            <v-icon class="ringing font-zise-bell">mdi-bell-ring</v-icon>
            {{ $t('news.news') }}
          </v-btn>
        </div> -->

        <v-dialog
          v-model="showNotification"
          max-width="1500px"
          @keydown.esc="showNotification = false"
        >
          <template v-slot:activator="{ on }">
            <div class="alert-info" to="btn-noti">
              <v-btn
                v-if="!initModeFlag && news.newsSize != 0"
                dark
                left
                color="#FFB74D"
                @click="getAllNews"
                class="btn-fab"
                v-on="on"
              >
                <v-icon>mdi-bell-ring</v-icon>
                {{ $t('news.news') }}
              </v-btn>
            </div>
          </template>

          <v-card>
            <v-expand-transition>
              <v-expansion-panels accordion>
                <template v-for="(newsCategory, type) in news">
                  <v-expansion-panel :key="type" v-if="newsCategory.length">
                    <v-expansion-panel-header>
                      <div class="d-flex" style="width: 90%">
                        <span
                          class="px-2 label mr-1"
                          :style="{ color: newsCategory[0].item2 }"
                          >{{ newsCategory[0].item1 }}</span
                        >
                        <span class="noti-content mr-2"
                          >{{ newsCategory[0].news }}
                        </span>
                      </div>
                      <span
                        class="text-right flex-shrink-0"
                        style="font-size: 13px; color: darkgreen"
                        >{{ newsCategory.length }}{{ $t('news.counter') }}</span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-for="(item, index) in newsCategory.slice(1)"
                      :key="`new-${type}-${index}`"
                    >
                      <div class="d-flex" style="width: 85%; font-size: 14px">
                        <span
                          class="px-2 label mr-1"
                          :style="{ color: newsCategory[0].item2 }"
                          >{{ newsCategory[0].item1 }}
                        </span>
                        <span class="pr-1 noti-content">{{ item.news }}</span>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </v-expand-transition>
          </v-card>
        </v-dialog>

        <!-- MenuList -->
        <div class="menu-list">
          <v-list nav>
            <!-- Bug in Vuetify for first child of v-list not receiving proper border-radius -->
            <div />
            <template v-for="(link, index) in links">
              <template v-if="link.show === undefined ? true : link.show">
                <Navbar :link="link" :key="index" v-if="link.group" />
                <v-list-item
                  v-else-if="checkShowMenu(link.to)"
                  :key="index"
                  :to="link.click ? null : `${link.to}`"
                  :input-value="checkActiveDrawer(link.to)"
                  @click.stop="link.click ? link.click() : null"
                  :disabled="!!link.disabled"
                >
                  <v-list-item-action>
                    <v-icon>{{ link.icon }}</v-icon>
                  </v-list-item-action>

                  <v-list-item-title>
                    {{ link.text }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </template>
          </v-list>
        </div>

        <!-- password input-->
        <v-container container-fluid>
          <v-row>
            <v-col cols="12" md="6" offset="0" offset-md="3">
              <v-dialog v-model="dialog" :max-width="options.width">
                <validation-observer
                  ref="obs"
                  v-slot="{ handleSubmit, invalid }"
                >
                  <form @submit.prevent="handleSubmit()">
                    <v-card>
                      <v-app-bar dark dense color="primary">
                        <v-toolbar-title>
                          {{ $t('maintenance.edit.password.title') }}
                        </v-toolbar-title>
                        <v-spacer />
                        <v-btn icon @click="cancel">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-app-bar>
                      <v-card-text class="pa-md-10">
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="{ required: true, max: 256 }"
                          :name="$t('maintenance.edit.password.old_password')"
                        >
                          <v-text-field
                            id="oldPassword"
                            v-model="oldPassword"
                            :label="
                              $t('maintenance.edit.password.old_password')
                            "
                            name="old_password"
                            autocomplete="current-password"
                            prepend-icon="mdi-lock"
                            type="password"
                            placeholder="Password"
                            maxlength="256"
                            :error-messages="errors"
                          />
                        </validation-provider>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            max: 256,
                            min: 8,
                            uppercase: true,
                            lowercase: true,
                            number: true,
                            special_char: true,
                          }"
                          :name="$t('maintenance.edit.password.new_password')"
                        >
                          <v-text-field
                            id="newPassword"
                            v-model="newPassword"
                            :label="
                              $t('maintenance.edit.password.new_password')
                            "
                            name="new_password"
                            autocomplete="new-password"
                            prepend-icon="mdi-lock"
                            type="password"
                            placeholder="Password"
                            maxlength="256"
                            :error-messages="errors"
                          />
                        </validation-provider>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            max: 256,
                            min: 8,
                            uppercase: true,
                            lowercase: true,
                            number: true,
                            special_char: true,
                            is: newPassword,
                          }"
                          :name="
                            $t('maintenance.edit.password.confirmed_password')
                          "
                        >
                          <v-text-field
                            id="confirmedPassword"
                            v-model="confirmedPassword"
                            :label="
                              $t('maintenance.edit.password.confirmed_password')
                            "
                            name="confirmed_password"
                            autocomplete="new-password"
                            prepend-icon="mdi-lock"
                            type="password"
                            placeholder="Password"
                            maxlength="256"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions class="mt-5">
                        <v-btn
                          color="primary darken-1"
                          :disabled="invalid"
                          @click="changePassword()"
                        >
                          {{ $t('buttons.change') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </form>
                </validation-observer>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
        <!-- End Password input-->
        <notification ref="notification" />
      </div>

      <portal to="btn-noti">
        <v-btn
          v-if="!buttonActive"
          fab
          dark
          fixed
          right
          bottom
          color="#46ce93"
          class="btn-to-top"
          :title="$t('menu.page-top')"
          @click="pageTop"
          ><v-icon size="34">mdi-arrow-up-bold </v-icon></v-btn
        >
      </portal>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import notification from '@/components/common/dialogs/Notification.vue'
import Navbar from './Navbar.vue'

export default {
  components: {
    notification,
    Navbar,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      // for password edit
      showNotification: false,
      dialog: false,
      options: {
        color: 'secondary',
        width: '60%',
      },
      oldPassword: '',
      newPassword: '',
      confirmedPassword: '',
      initModeFlag: true,
      buttonActive: true,
      i18nLocale: '',
      news: null,
    }
  },
  async created () {
    // show drawer at init
    this.SET_STATE({ name: 'drawerWidth', value: 256 })
    await this.getAllNews()
    this.initModeFlag = false
    this.initLanguage()
  },
  computed: {
    ...mapState('codes', ['userInfo', 'settings']),
    ...mapState('app', ['user']),
    ...mapGetters('app', ['checkShowMenu']),
    inputValue: {
      get () {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.doSetDrawer(val)
      },
    },
    links: {
      get: function () {
        const _links = []
        _links.push(
          {
            to: '/dashboard',
            icon: 'mdi-home-analytics',
            text: this.$i18n.t('menu.customDashboardName'),
          },
          {
            to: '/dashboard-analytics',
            icon: 'mdi-sine-wave',
            text: this.$i18n.t('menu.dashboard-analytics'),
          },
          {
            to: '/activity/input',
            icon: 'mdi-chart-box-plus-outline',
            text: this.$i18n.t('menu.activity-input'),
          },
          {
            group: true,
            title: this.$i18n.t('menu.setting-product-management'),
            icon: 'mdi-package-variant-closed',
            show: this.checkShowMenu('/product-management/'),
            items: [
              {
                to: '/product-management/production',
                icon: 'mdi-package-variant-closed-plus',
                text: this.$i18n.t('menu.product-management-production'),
              },
              {
                to: '/product-management/activity',
                icon: 'mdi-text-box-plus-outline',
                text: this.$i18n.t('menu.product-management-activity'),
              },
              {
                to: '/product-management/simulation',
                icon: 'mdi-vpn',
                text: this.$i18n.t('menu.product-management-simulation'),
              },
            ],
          },
          {
            to: '/reduction-target-input',
            icon: 'mdi-decimal-decrease',
            text: this.$i18n.t('menu.reduction-target-input'),
          },
          {
            to: '/alert-setting',
            icon: 'mdi-alert-octagon-outline',
            text: this.$i18n.t('menu.alert-setting'),
          },
          {
            group: true,
            title: this.$i18n.t('menu.setting'),
            icon: 'mdi-cog',
            show: this.checkShowMenu('/setting'),
            items: [
              {
                to: '/setting/organization',
                icon: 'mdi-sitemap',
                text: this.$i18n.t('menu.setting-organization'),
              },
              {
                to: '/setting/company',
                icon: 'mdi-office-building',
                text: this.$i18n.t('menu.setting-company'),
              },
              {
                to: '/setting/fiscal-year',
                icon: 'mdi-calendar-month',
                text: this.$i18n.t('menu.setting-fiscal-year'),
              },
              {
                to: '/setting/user',
                icon: 'mdi-account-group',
                text: this.$i18n.t('menu.setting-user'),
              },
              {
                to: '/setting/industry-templates-master',
                icon: 'mdi-format-list-bulleted-square',
                text: this.$i18n.t('menu.setting-industry-templates-master'),
              },
              {
                to: '/setting/news',
                icon: 'mdi-bell-cog',
                text: this.$i18n.t('menu.setting-news'),
              },
              {
                to: '/setting/emission-version-management',
                icon: 'mdi-electric-switch-closed',
                text: this.$i18n.t('menu.setting-emission-version-management'),
              },
              {
                group: true,
                subGroup: true,
                title: this.$i18n.t('menu.setting-master-maintenance'),
                show: this.checkShowMenu('/setting/mst/'),
                items: [
                  {
                    to: '/setting/mst/master-register-csv-export',
                    text: this.$i18n.t(
                      'menu.setting-mst-master-register-csv-export'
                    ),
                  },
                  {
                    to: '/setting/mst/retail-electricity-providers',
                    text: this.$i18n.t(
                      'menu.setting-mst-retail-electricity-providers'
                    ),
                  },
                  {
                    to: '/setting/mst/retail-electricity-providers-emission',
                    text: this.$i18n.t(
                      'menu.setting-mst-retail-electricity-providers-emission'
                    ),
                  },
                  {
                    to: '/setting/mst/emissions-unit-value-master',
                    text: this.$i18n.t(
                      'menu.setting-mst-emissions-unit-value-master'
                    ),
                  },
                  {
                    to: '/setting/mst/transport-master',
                    text: this.$i18n.t('menu.setting-mst-transport-master'),
                  },
                ],
              },
            ],
          },
          {
            to: '/data-extraction',
            icon: 'mdi-calculator-variant-outline',
            text: this.$i18n.t('menu.data-extraction'),
          },
          //（ジャパンラーニングのコンテンツが完成するまでは一旦）メニューにある「排出量算定ガイド」を非表示にしておく
          // {
          //   to: '/emissions-calculation-guide',
          //   icon: 'mdi-video-box',
          //   text: this.$i18n.t('menu.emissions-calculation-guide'),
          // },
          {
            icon: 'mdi-logout',
            text: this.$i18n.t('menu.logout'),
            click: this.doLogout,
          },
          //this line is fix height of the aside menu does not get hidden by the footer
          {
            to: '',
            icon: '',
            text: '',
            disabled: true,
          }
        )

        return _links
      },
    },
  },
  mounted () {
    this.setBorderWidth()
    this.setEvents()
    window.addEventListener('scroll', this.scrollWindow)
  },
  watch: {
    // showNotification(value) {
    //   if (!value) {
    //     this.getNews()
    //   }
    // },
    '$route.path': {
      handler: function (value) {
        this.getAllNews()
      },
    },
  },
  methods: {
    ...mapMutations('app', ['SET_TOKEN', 'TOGGLE_NOTIFICATION', 'SET_STATE']),
    ...mapActions('app', ['doSetDrawer', 'getNews', 'doLogout']),
    ...mapActions('item', {
      doSetItemSearchCriteria: 'doSetItemSearchCriteria',
      doSetItemCurrentPage: 'doSetCurrentPage',
    }),
    ...mapActions('request', {
      doSetRequestSearchCriteria: 'doSetRequestSearchCriteria',
      doSetRequestCurrentPage: 'doSetCurrentPage',
    }),
    async getAllNews () {
      const today = new Date()
      const date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate()
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      const paramFull = {
        systemDate: date + ' ' + time,
      }
      const resNew = await this.$api.dashboard.getNewsInit(paramFull)
      this.news = {
        emergency: resNew.result.urgentNews,
        important: resNew.result.importantNews,
        normal: resNew.result.normalNews,
        newsSize: resNew.result.newsSize,
      }
    },
    setBorderWidth () {
      let i = this.$refs.drawer.$el.querySelector(
        '.v-navigation-drawer__border'
      )
      i.style.width = '5px'
      i.style.cursor = 'ew-resize'
      i.style.backgroundColor = '#C6C0BA'
    },
    setEvents () {
      const el = this.$refs.drawer.$el
      const drawerBorder = el.querySelector('.v-navigation-drawer__border')

      const resize = (e) => {
        document.body.style.cursor = 'ew-resize'
        if (e.clientX > 350) return
        el.style.width = e.clientX + 'px'
        this.SET_STATE({ name: 'drawerWidth', value: e.clientX })
      }

      drawerBorder.addEventListener(
        'mousedown',
        (e) => {
          e.preventDefault()
          el.style.transition = 'initial'
          document.addEventListener('mousemove', resize, false)
        },
        false
      )

      document.addEventListener(
        'mouseup',
        () => {
          el.style.transition = ''
          document.body.style.cursor = ''
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    },
    cancel () {
      this.dialog = false
      this.clearInput()
      this.$refs.obs.reset()
    },
    clearInput () {
      this.newPassword = ''
      this.oldPassword = ''
      this.confirmedPassword = ''
    },
    checkActiveDrawer (to) {
      let result = false
      let routeString = this.$route.path

      result = routeString == to

      return result
    },
    async changePassword () {
      try {
        this.newPassword = ''
        this.oldPassword = ''
        this.dialog = false
        this.$refs.notification.open(
          this.$t('edit.password.title'),
          this.$t('edit.password.change_success'),
          { color: 'success', width: '50%' }
        )
      } catch (e) {
        this.dialog = false
        this.$refs.notification.open(
          this.$t('edit.password.title'),
          this.$t('edit.password.change_failed'),
          { color: 'warning', width: '50%' }
        )
      } finally {
        this.clearInput()
        this.$refs.obs.reset()
      }
    },
    pageTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    scrollWindow () {
      const start = 200 //topから200pxスクロールしたらボタン登場
      var scroll = window.scrollY //垂直方向
      if (start <= scroll) {
        this.buttonActive = false
      } else {
        this.buttonActive = true
      }
    },
    async changeLanguage (value) {
      localStorage.setItem('language', value)
      window.location.reload()
    },
    async initLanguage () {
      const lang = localStorage.getItem('language')
      if (!lang) {
        this.i18nLocale = 'ja'
      } else {
        this.i18nLocale = lang
      }
    },
  },
}
</script>
<style scoped>
::v-deep .v-list-item__title {
  font-size: 14px !important;
}

.notification {
  position: absolute;
  left: 15px;
  bottom: 60px;
}

.notification .v-expansion-panel {
  max-height: 400px;
  overflow-y: auto;
  background-color: #fef3eb;
}
::v-deep .notification .v-expansion-panel-header {
  padding: 16px 10px;
}
::v-deep .notification .v-expansion-panel-content {
  padding: 16px 10px;
}

::v-deep .notification .v-expansion-panel-content__wrap {
  padding: 0px;
}

.label {
  background-color: #fef3eb;
  border-radius: 2px;
  align-self: flex-start;
  flex-shrink: 0;
  font-size: 1rem;
}

.noti-content {
  word-break: break-word;
}

.btn-fab {
  width: 180px !important;
  height: 35px !important;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.ringing {
  animation-timing-function: ease-in-out;
  animation-name: ring;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  animation-delay: 0.7s;
  transform-origin: 50% 4px;
}

.font-zise-bell {
  font-size: 30px !important;
}

.v-navigation-drawer--fixed {
  z-index: unset !important;
}

.normal-flag {
  margin: unset !important;
}
</style>
