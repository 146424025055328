import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,_vm._b({style:(_vm.styles),attrs:{"height":_vm.fullHeight ? '100%' : ''}},'v-card',_vm.$attrs,false),[(_vm.topBar)?_c(VCard,{staticClass:"v-card--material__header d-flex align-center justify-space-between",attrs:{"color":_vm.color,"dark":"","min-height":"40"}},[_c(VCardTitle,[_vm._v("\n      "+_vm._s(_vm.title)+"\n      "),(_vm.guide)?_c(VTooltip,{attrs:{"top":"","open-on-hover":false,"open-on-click":true,"max-width":"25em","z-index":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({on:{"click":on.click}},on),[_vm._v("\n            mdi-help-circle-outline\n          ")])]}}],null,false,681586056)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.guide.startsWith('Q0') ? _vm.$constants.GUIDE[_vm.guide] : _vm.guide))])]):_vm._e(),_vm._v(" "),(_vm.help)?_c('div',[_c(VIcon,{on:{"click":function($event){return _vm.$emit('open-category-guide', _vm.help)}}},[_vm._v("\n          mdi-help-circle-outline\n        ")])],1):_vm._e()],1),_vm._v(" "),_c(VSpacer)],1):_vm._e(),_vm._v(" "),_c('div',[(this.$slots.title)?_c(VCardTitle,{staticClass:"text-subtitle-1"},[_vm._t("title")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"v-card-body"},[_vm._t("main"),_vm._v(" "),_vm._t("buttons")],2)],1),_vm._v(" "),(_vm.$slots.actions)?_c(VDivider):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }