<template>
  <v-app>
    <nuxt />
  </v-app>
</template>

<script>
export default {
  head() {
    return {
      link: [
        {
          //既存のcssを無効化
          rel: 'stylesheet',
          href: '~/assets/style/style.scss',
          lang: 'scss',
          disabled: true,
        },
        {
          //既存のcssを無効化
          rel: 'stylesheet',
          href: '~/assets/css/main.css',
          lang: 'css',
          disabled: true,
        },
      ],
    }
  },
}
</script>