<template>
  <v-app>
    <core-app-bar />
    <core-drawer />
    <core-view />

    <core-footer />
    <v-dialog v-model="dialog" persistent max-width="50%">
      <v-card color="secondary" dark>
        <v-card-text v-if="dialog">
          {{ dialog }}
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :max-width="800" :timeout="timeout">
      <v-icon v-if="snackbarType === 'error'" color="error"
        >mdi-alert-circle</v-icon
      >
      <span v-html="snackbarMessage"></span>
      <template #action="{ attrs }">
        <v-btn :color="snackbarType" text v-bind="attrs" @click="closeSnackbar">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Drawer from '@/components/vendor/core/Drawer'
import Footer from '@/components/vendor/core/Footer'
import AppBar from '@/components/vendor/core/AppBar'
import View from '@/components/vendor/core/View'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    CoreDrawer: Drawer,
    CoreFooter: Footer,
    CoreAppBar: AppBar,
    CoreView: View,
  },
  props: {
    source: { type: String, default: '' },
  },
  data: () => {
    return {
      snackbarMessage: null,
      multiLine: true,
      snackbarType: 'success',
      timeout: -1,
    }
  },
  computed: {
    ...mapState('app', ['loadingQueue']),
    ...mapState('codes', ['latestMasterUpdated']),
    dialog: {
      get: function () {
        if (!this.loadingQueue || this.loadingQueue.length == 0) return false
        return this.loadingQueue[Object.keys(this.loadingQueue)[0]]
      },
      set: function (val) {
        null
      },
    },
    snackbar: {
      get: function () {
        return this.snackbarMessage ? true : false
      },
      set: function (val) {
        if (!val) {
          this.snackbarMessage = null
        }
      },
    },
  },
  watch: {},
  created() {
    this.setListener()
  },
  mounted() {
    this.doSetCodeStore()
  },
  methods: {
    ...mapActions('codes', {
      doSetCodeStore: 'doSetCodeStore',
      doSetLocationList: 'doSetLocationList',
      doSetDeptList: 'doSetDeptList',
    }),
    setListener() {
      this.$nuxt.$on('set-snackbar-message', this.setSnackbarMessage)
    },
    setSnackbarMessage(message, type = 'success', timeout) {
      this.snackbarMessage = message
      this.snackbarType = type
      if (timeout) {
        this.timeout = timeout
      } else if (type === 'success') {
        this.timeout = 5000
      } else {
        this.timeout = -1
      }
    },
    closeSnackbar() {
      this.snackbarMessage = null
    },
  },
}
</script>
