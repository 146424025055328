<template>
  <v-list-group
    :prepend-icon="link.icon"
    no-action
    :value="false"
    color="white"
    :sub-group="link.subGroup"
    :class="{ subgroup: link.subGroup }"
  >
    <template #activator>
      <v-list-item-title>
        {{ link.title }}
      </v-list-item-title>
    </template>

    <template v-for="(subLink, subIndex) in link.items">
      <template v-if="subLink.show === undefined ? true : subLink.show">
        <Navbar :key="subIndex" v-if="subLink.group" :link="subLink" />

        <v-list-item
          v-else-if="checkShowMenu(subLink.to)"
          :key="subIndex"
          :to="subLink.click ? null : `${subLink.to}`"
          :input-value="checkActiveDrawer(subLink.to)"
          @click.stop="subLink.click ? subLink.click() : null"
          ><v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title v-bind="attrs" v-on="on">
                {{ subLink.text }}
              </v-list-item-title>
              <v-list-item-action v-if="subLink.icon">
                <v-icon>{{ subLink.icon }}</v-icon>
              </v-list-item-action>
            </template>
            <span> {{ subLink.text }}</span>
          </v-tooltip>
        </v-list-item>
      </template>
    </template>
  </v-list-group>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Navbar',
  props: {
    link: Object,
  },
  computed: {
    ...mapGetters('app', ['checkShowMenu']),
  },
  methods: {
    checkActiveDrawer(to) {
      let result = false
      let routeString = this.$route.path

      result = routeString == to

      return result
    },
  },
}
</script> 
<style scoped>
.subgroup > ::v-deep.v-list-item {
  flex-direction: row-reverse;
}

.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-left: 55px !important;
}
</style>