<template>
  <v-app>
    <core-view />
    <core-footer />
    <v-dialog v-model="dialog" persistent max-width="50%">
      <v-card color="secondary" dark>
        <v-card-text v-if="dialog">
          {{ dialog }}
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ snackbarMessage }}
      <template #action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="closeSnackbar">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Footer from "@/components/vendor/core/Footer"
import View from "@/components/vendor/core/View"
import { mapState } from "vuex"

export default {
  components: {
    CoreFooter: Footer,
    CoreView: View,
  },
  props: {
    source: { type: String, default: "" },
  },
  data: () => ({
    drawer: null,
    snackbarMessage: null,
    multiLine: true,
  }),
  computed: {
    ...mapState("app", ["loadingQueue"]),
    dialog: {
      get: function () {
        if (!this.loadingQueue || this.loadingQueue.length == 0) return false
        return this.loadingQueue[Object.keys(this.loadingQueue)[0]]
      },
      set: function (val) {
        null
      },
    },
    snackbar: {
      get: function () {
        return this.snackbarMessage ? true : false
      },
      set: function (val) {
        if (!val) {
          this.snackbarMessage = null
        }
      },
    },
  },
  created() {
    this.setListener()
  },
  methods: {
    setListener() {
      this.$nuxt.$on("set-snackbar-message", this.setSnackbarMessage)
    },
    setSnackbarMessage(message) {
      this.snackbarMessage = message
    },
    closeSnackbar() {
      this.snackbarMessage = null
    },
  },
}
</script>
